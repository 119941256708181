<template>
  <div id="jci-program-create">
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step step="1" :complete="step > 1">
          Event Details
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2" :complete="step > 2">
          Host Details
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> Participant Details </v-stepper-step>

        <v-divider></v-divider>

        <!-- <v-stepper-step step="4">Change Password</v-stepper-step> -->
      </v-stepper-header>
      <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
        <br />
        <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
      </div>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <v-card-title>
              <span class="headline">Event Details</span>
            </v-card-title>
            <v-form ref="form3" v-model="valid3" lazy-validation>
              <v-card-text>
                <small
                  ><span class="text-danger">*</span> indicates required
                  field</small
                >
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <label>
                        <h6 class="mycard-text1 fsize-3">
                          <span class="text-danger">*</span> Event type
                        </h6>
                      </label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        :loading="EventTypeOptionsLoading"
                        :items="EventTypeOptions"
                        :rules="EventTypeRules"
                        v-model="EventType"
                        hint="Select a event type"
                        persistent-hint
                        dense
                        clearable
                        required
                        outlined
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if="EventType > 0">
                    <v-col cols="12" sm="6" md="3">
                      <h4>Description</h4>
                      {{ SelectedEventType.description }}
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <h4>Eligibility</h4>
                      {{ SelectedEventType.eligibility_content }}
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <h4>Subsidy</h4>
                      {{ SelectedEventType.subsidy_amount_txt }}
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <h4>Issue receipt</h4>
                      {{ SelectedEventType.issue_receipt_txt }}
                    </v-col>
                  </v-row>
                  <br />
                  <v-divider />
                  <br />
                  <v-row v-if="EventType > 0">
                    <v-col cols="12" sm="6" md="3">
                      <label>
                        <h6><span class="text-danger">*</span>From Date</h6>
                      </label>
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            required
                            v-model="FromDate"
                            label="From Date"
                            hint="Select a From Date"
                            persistent-hint
                            readonly
                            v-on="on"
                            background-color="#F3F6F9"
                            class="
                              form-control form-control-lg form-control-solid
                            "
                            validate-on-blur
                            solo
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="FromDate"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <label>
                        <h6>To Date</h6>
                      </label>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            required
                            v-model="ToDate"
                            label="To Date"
                            hint="Select a To Date"
                            persistent-hint
                            readonly
                            v-on="on"
                            background-color="#F3F6F9"
                            class="
                              form-control form-control-lg form-control-solid
                            "
                            validate-on-blur
                            solo
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="ToDate"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <h6 class="mycard-text1 fsize-3">Custom Name</h6>
                      <v-text-field
                        v-model="CustomName"
                        :rules="CustomNameRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <h6 class="mycard-text1 fsize-3">
                        Venue <small>(optional)</small>
                      </h6>
                      <v-text-field
                        v-model="Venue"
                        :rules="VenueRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <label>
                        <h6 class="mycard-text1 fsize-3">
                          <span class="text-danger">*</span> State
                        </h6>
                      </label>
                      <!-- append-outer-icon="mdi-refresh" -->
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        :loading="StateIdOptionsLoading"
                        :items="StateIdOptions"
                        :rules="StateIdRules"
                        v-model="StateId"
                        @click:append-outer="getStateIdOptions"
                        required
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <label>
                        <h6 class="mycard-text1 fsize-3">
                          <span class="text-danger">*</span> District
                        </h6>
                      </label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        :loading="DistrictIdOptionsLoading"
                        :items="DistrictIdOptions"
                        :rules="DistrictIdRules"
                        v-model="DistrictId"
                        @click:append-outer="getDistrictIdOptions"
                        required
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <label>
                        <h6 class="mycard-text1 fsize-3">
                          <span class="text-danger">*</span> City
                        </h6>
                      </label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        :loading="CityIdOptionsLoading"
                        :items="CityIdOptions"
                        :rules="CityIdRules"
                        v-model="CityId"
                        @click:append-outer="getCityIdOptions"
                        required
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <h6 class="mycard-text1 fsize-3">
                        Registration max count for over all (0 for unlimited)
                      </h6>
                      <v-text-field
                        v-model="RegistrationOverallCount"
                        :rules="RegistrationOverallCountRules"
                        hint="0 for unlimited"
                        persistent-hint
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <h6 class="mycard-text1 fsize-3">
                        Registration max count for per user (0 for unlimited)
                      </h6>
                      <v-text-field
                        v-model="RegistrationUserCount"
                        :rules="RegistrationUserCountRules"
                        hint="0 for unlimited"
                        persistent-hint
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- v-if="EventType > 0" -->
                <v-btn
                  :disabled="!valid3"
                  @click.prevent="validateStep(1)"
                  color="primary"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card>
            <v-form ref="form2" v-model="valid2" lazy-validation>
              <v-card-title>
                <span class="headline">Host Details</span>
              </v-card-title>
              <v-card-text>
                <small
                  ><span class="text-danger">*</span> indicates required
                  field</small
                >
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Hosted by
                          <strong>JCI India?</strong>
                        </h6>
                      </label>
                      <v-switch
                        v-model="HostType"
                        :label="`${HostType == 1 ? 'Yes' : 'No'}`"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" v-if="!HostType">
                      <label>
                        <h6><span class="text-danger">*</span> Zone</h6>
                      </label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        :loading="HostZoneCodeOptionsLoading"
                        :items="HostZoneCodeOptions"
                        :rules="HostZoneCodeRules"
                        v-model="HostZoneCode"
                        hint="Select a Zone"
                        persistent-hint
                        dense
                        clearable
                        required
                        outlined
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" v-if="!HostType">
                      <label>
                        <h6><span class="text-danger">*</span> LOM</h6>
                      </label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        v-model="HostLomCode"
                        :rules="HostLomCodeRules"
                        :items="HostLomCodeOptions"
                        :loading="HostLomCodeOptionsLoading"
                        dense
                        clearable
                        required
                        outlined
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey mr-3" @click="step = 1"> Previous </v-btn>
                <v-btn
                  :disabled="!valid2"
                  @click.prevent="validateStep(2)"
                  color="primary"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card>
            <v-form ref="form1" v-model="valid1" lazy-validation>
              <v-card-title>
                <span class="headline">Participant Details</span>
              </v-card-title>
              <v-card-text>
                <small
                  ><span class="text-danger">*</span> indicates required
                  field</small
                >
                <v-container>
                  <v-row wrap>
                    <v-col cols="12" sm="6" md="6">
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Can members from
                          all zone participate?
                        </h6>
                      </label>
                      <v-switch
                        v-model="ParticipantZoneType"
                        :label="`${ParticipantZoneType == 1 ? 'Yes' : 'No'}`"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="!ParticipantZoneType">
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Zone ({{
                            ParticipantZoneCode.length
                          }}
                          selected)
                        </h6>
                      </label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        v-model="ParticipantZoneCode"
                        :rules="ParticipantZoneCodeRules"
                        :items="ParticipantZoneCodeOptions"
                        :loading="ParticipantZoneCodeOptionsLoading"
                        item-text="text"
                        item-value="value"
                        hint="Select a Zone"
                        persistent-hint
                        dense
                        chips
                        clearable
                        required
                        outlined
                        multiple
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item, 1)"
                          >
                            {{ data.item.text }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="!ParticipantZoneType">
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Can members from
                          all LO participate?
                        </h6>
                      </label>
                      <v-switch
                        v-model="ParticipantLomType"
                        :label="`${ParticipantLomType == 1 ? 'Yes' : 'No'}`"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="!ParticipantZoneType && !ParticipantLomType">
                      <label>
                        <h6>
                          <span class="text-danger">*</span> LO ({{
                            ParticipantLomCode.length
                          }}
                          selected)
                        </h6>
                      </label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        v-model="ParticipantLomCode"
                        :rules="ParticipantLomCodeRules"
                        :items="ParticipantLomCodeOptions"
                        :loading="ParticipantLomCodeOptionsLoading"
                        item-text="text"
                        item-value="value"
                        hint="Select a LO"
                        persistent-hint
                        dense
                        chips
                        clearable
                        required
                        outlined
                        multiple
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item, 6)"
                          >
                            {{ data.item.text }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row wrap>
                    <v-col cols="12" sm="6" md="6">
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Can non member of
                          JCI participate?
                        </h6>
                      </label>
                      <v-switch
                        v-model="ParticipantNonMember"
                        :label="`${ParticipantNonMember == 1 ? 'Yes' : 'No'}`"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Can members from
                          all designation participate?
                        </h6>
                      </label>
                      <v-switch
                        v-model="ParticipantAllDesignation"
                        :label="`${
                          ParticipantAllDesignation == 1 ? 'Yes' : 'No'
                        }`"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row wrap v-if="!ParticipantAllDesignation">
                    <v-col cols="12" sm="6" md="6">
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Can members from
                          all NGB participate?
                        </h6>
                      </label>
                      <v-switch
                        v-model="NationalDesigntionType"
                        :label="`${NationalDesigntionType == 1 ? 'Yes' : 'No'}`"
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="!NationalDesigntionType"
                    >
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Designation ({{
                            NationalDesigntion.length
                          }}
                          selected)
                        </h6>
                      </label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        v-model="NationalDesigntion"
                        :rules="NationalDesigntionRules"
                        :items="NationalDesigntionOptions"
                        :loading="NationalDesigntionOptionsLoading"
                        item-text="text"
                        item-value="value"
                        hint="Select a designation"
                        persistent-hint
                        dense
                        chips
                        clearable
                        required
                        outlined
                        multiple
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item, 2)"
                          >
                            {{ data.item.text }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row wrap v-if="!ParticipantAllDesignation">
                    <v-col cols="12" sm="6" md="6">
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Can members from
                          all National Committee participate?
                        </h6>
                      </label>
                      <v-switch
                        v-model="CommitteeDesigntionType"
                        :label="`${
                          CommitteeDesigntionType == 1 ? 'Yes' : 'No'
                        }`"
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="!CommitteeDesigntionType"
                    >
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Designation ({{
                            CommitteeDesigntion.length
                          }}
                          selected)
                        </h6>
                      </label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        v-model="CommitteeDesigntion"
                        :rules="CommitteeDesigntionRules"
                        :items="CommitteeDesigntionOptions"
                        :loading="CommitteeDesigntionOptionsLoading"
                        item-text="text"
                        item-value="value"
                        hint="Select a designation"
                        persistent-hint
                        dense
                        chips
                        clearable
                        required
                        outlined
                        multiple
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item, 5)"
                          >
                            {{ data.item.text }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row wrap v-if="!ParticipantAllDesignation">
                    <v-col cols="12" sm="6" md="6">
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Can members from
                          all ZGB participate?
                        </h6>
                      </label>
                      <v-switch
                        v-model="ZoneDesigntionType"
                        :label="`${ZoneDesigntionType == 1 ? 'Yes' : 'No'}`"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="!ZoneDesigntionType">
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Designation ({{
                            ZoneDesigntion.length
                          }}
                          selected)
                        </h6>
                      </label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        v-model="ZoneDesigntion"
                        :rules="ZoneDesigntionRules"
                        :items="ZoneDesigntionOptions"
                        :loading="ZoneDesigntionOptionsLoading"
                        item-text="text"
                        item-value="value"
                        hint="Select a designation"
                        persistent-hint
                        dense
                        chips
                        clearable
                        required
                        outlined
                        multiple
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item, 3)"
                          >
                            {{ data.item.text }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row wrap v-if="!ParticipantAllDesignation">
                    <v-col cols="12" sm="6" md="6">
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Can members from
                          all LGB participate?
                        </h6>
                      </label>
                      <v-switch
                        v-model="LomDesigntionType"
                        :label="`${LomDesigntionType == 1 ? 'Yes' : 'No'}`"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="!LomDesigntionType">
                      <label>
                        <h6>
                          <span class="text-danger">*</span> Designation ({{
                            LomDesigntion.length
                          }}
                          selected)
                        </h6>
                      </label>
                      <v-autocomplete
                        :reduce="(option) => option.value"
                        v-model="LomDesigntion"
                        :rules="LomDesigntionRules"
                        :items="LomDesigntionOptions"
                        :loading="LomDesigntionOptionsLoading"
                        item-text="text"
                        item-value="value"
                        hint="Select a designation"
                        persistent-hint
                        dense
                        chips
                        clearable
                        required
                        outlined
                        multiple
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item, 4)"
                          >
                            {{ data.item.text }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey mr-3" @click="step = 2"> Previous </v-btn>
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="validateStep(3)"
                  :loading="SubmitFlag"
                  color="primary"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-form ref="form4" v-model="valid4" lazy-validation> </v-form>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  data() {
    return {
      step: 1,
      valid1: true,
      valid2: true,
      valid3: true,
      valid4: true,
      valid5: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      SendOtpFlag: false,
      SendOtpLoadingFlag: false,
      VerifyOtpFlag: false,
      VerifyOtpLoadingFlag: false,
      ResendOtpFlag: false,

      MemberId: "",

      MemberName: "",

      // For Step 1

      SelectedEventType: {},

      EventTypeRules: [(v) => !!v || "Event type is required"],
      EventType: "",
      EventTypeOptions: [],
      EventTypeOptionsLoading: false,

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      menu2: false,

      CustomName: "",
      CustomNameRules: [],

      Venue: "",
      VenueRules: [],

      StateIdRules: [(v) => !!v || "State is required"],
      StateId: "",
      StateIdOptions: [],
      StateIdOptionsLoading: false,

      DistrictIdRules: [(v) => !!v || "District is required"],
      DistrictId: "",
      DistrictIdOptions: [],
      DistrictIdOptionsLoading: false,

      CityIdRules: [(v) => !!v || "City is required"],
      CityId: "",
      CityIdOptions: [],
      CityIdOptionsLoading: false,

      RegistrationOverallCount: "",
      RegistrationOverallCountRules: [
        (v) => !!v || "Registration overall count is required",
      ],

      RegistrationUserCount: "",
      RegistrationUserCountRules: [
        (v) => !!v || "Registration per user count is required",
      ],

      // For Step 2

      HostType: "",

      HostZoneCodeRules: [],
      HostZoneCode: "",
      HostZoneCodeOptions: [(v) => !!v || "Zone is required"],
      HostZoneCodeOptionsLoading: false,

      HostLomCodeRules: [(v) => !!v || "LO is required"],
      HostLomCode: "",
      HostLomCodeOptions: [],
      HostLomCodeOptionsLoading: false,

      // For Step 3

      RegistrationTpe: [],

      RegistrationTpeOptions: [
        { value: 1, text: "JCI India Member" },
        { value: 2, text: "JCI India Senior Member" },
        { value: 3, text: "JCOM Member" },
        { value: 4, text: "Non-Member" },
      ],

      ParticipantZoneType: "",

      ParticipantZoneCodeRules: [],
      ParticipantZoneCode: [],
      ParticipantZoneCodeOptions: [(v) => !!v || "Zone is required"],
      ParticipantZoneCodeOptionsLoading: false,

      ParticipantLomType: "",

      ParticipantLomCodeRules: [],
      ParticipantLomCode: [],
      ParticipantLomCodeOptions: [(v) => !!v || "LO is required"],
      ParticipantLomCodeOptionsLoading: false,

      ParticipantNonMember: false,

      ParticipantAllDesignation: false,

      NationalDesigntionType: "",

      NationalDesigntionRules: [],
      NationalDesigntion: [],
      NationalDesigntionOptions: [(v) => !!v || "Designation is required"],
      NationalDesigntionOptionsLoading: false,

      ZoneDesigntionType: "",

      ZoneDesigntionRules: [],
      ZoneDesigntion: [],
      ZoneDesigntionOptions: [(v) => !!v || "Designation is required"],
      ZoneDesigntionOptionsLoading: false,

      LomDesigntionType: "",

      LomDesigntionRules: [],
      LomDesigntion: [],
      LomDesigntionOptions: [(v) => !!v || "Designation is required"],
      LomDesigntionOptionsLoading: false,

      CommitteeDesigntionType: "",

      CommitteeDesigntionRules: [],
      CommitteeDesigntion: [],
      CommitteeDesigntionOptions: [(v) => !!v || "Designation is required"],
      CommitteeDesigntionOptionsLoading: false,

      uploadPercentage: 0,
      rows: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
      RoleId: "",

      ProgramId: "",
      RecordData: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var ProgramId = this.$route.query.v2;
        ProgramId = ProgramId == (null || undefined) ? 0 : ProgramId;
        console.log({ ProgramId });
        this.ProgramId = ProgramId;

        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        } else {
          this.PageTitle = tr.PageTitle;
          this.PageDescription = tr.PageDescription;
          this.FloatingButtonOptions = tr.FloatingButtonOptions;

          var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
          console.log({ CurrentJciYearId });
          this.JciYearCode = CurrentJciYearId;

          var YearName = parseInt(tr.CurrentJciYearName);
          console.log({ YearName });
          this.YearName = YearName;

          var CurrentYearId = this.$session.get("CurrentYearId");
          CurrentYearId = 1;
          CurrentYearId =
            CurrentYearId == (null || undefined) ? 0 : CurrentYearId;
          this.CurrentYearId = CurrentYearId;

          var RoleId = this.$session.get("RoleId");
          RoleId = RoleId == (null || undefined) ? "" : RoleId;
          // RoleId = 4;
          this.RoleId = RoleId;

          this.getTableRecords();
        }
      }
    },
    RecordData: function () {
      console.log("watch RecordData");
      var tr = this.RecordData;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.getPageData();

        this.FromDate = tr.FromDate;
        this.ToDate = tr.ToDate;
        this.CustomName = tr.CustomName;
        this.Venue = tr.VenueDetails;
        this.RegistrationOverallCount = tr.ParticipantLimit;
        this.RegistrationUserCount = tr.UserLimit;
        this.HostType = tr.HostType;
        this.ParticipantZoneType = tr.ParticipantZoneType;
        this.ParticipantLomType = tr.ParticipantLomType;
        this.ParticipantNonMember = tr.ParticipantNonMember;
        this.ParticipantAllDesignation = tr.ParticipantAllDesignation;
        this.NationalDesigntionType = tr.NationalDesigntionType;
        this.ZoneDesigntionType = tr.ZoneDesigntionType;
        this.LomDesigntionType = tr.LomDesigntionType;
        this.CommitteeDesigntionType = tr.CommitteeDesigntionType;
      }
    },
    CopyMobileNo: function () {
      console.log("watch CopyMobileNo");
      var CopyMobileNo = this.CopyMobileNo;
      this.WhatsappNo = CopyMobileNo == 1 ? this.MobileNo : this.WhatsappNo;
    },
    BloodGroupIdOptions: function () {
      console.log("watch BloodGroupIdOptions");
      this.LoadingFlag = false;
      this.BloodGroupIdOptionsLoading = false;
    },
    EventType: function () {
      console.log("watch EventType");
      this.getEventTypeDetails();
    },
    EventTypeOptions: function () {
      console.log("watch EventTypeOptions");
      this.EventTypeOptionsLoading = false;
      this.EventType = this.RecordData.ProgramTypeId;
    },
    StateIdOptions: function () {
      console.log("watch StateIdOptions");
      this.LoadingFlag = false;
      this.StateIdOptionsLoading = false;
      var rows = this.RecordData;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log({ n1 });
      if (n1 > 0) {
        var StateCode = rows.StateId;
        console.log({ StateCode });
        this.StateId = StateCode;
      }
    },
    StateId: function () {
      console.log("watch StateId");
      this.getDistrictIdOptions();
    },
    DistrictIdOptions: function () {
      console.log("watch DistrictIdOptions");
      this.LoadingFlag = false;
      this.DistrictIdOptionsLoading = false;
      var rows = this.RecordData;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log({ n1 });
      if (n1 > 0) {
        var DistrictCode = rows.DistrictId;
        console.log({ DistrictCode });
        var idx = this.DistrictIdOptions.map((e) => e.value).indexOf(
          DistrictCode
        );
        console.log("idx=" + idx);
        this.DistrictId = idx >= 0 ? DistrictCode : "";
      }
    },
    DistrictId: function () {
      console.log("watch DistrictId");
      this.getCityIdOptions();
    },
    CityIdOptions: function () {
      console.log("watch CityIdOptions");
      this.LoadingFlag = false;
      this.CityIdOptionsLoading = false;
      var rows = this.RecordData;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log({ n1 });
      if (n1 > 0) {
        var CityCode = rows.CityId;
        console.log({ CityCode });
        var idx = this.CityIdOptions.map((e) => e.value).indexOf(CityCode);
        console.log("idx=" + idx);
        this.CityId = idx >= 0 ? CityCode : "";
      }
    },
    HostZoneCode: function () {
      console.log("watch HostZoneCode");
      this.getHostLomCodeOptions();
    },
    HostZoneCodeOptions: function () {
      console.log("watch HostZoneCodeOptions");
      this.HostZoneCodeOptionsLoading = false;
    },
    HostType: function () {
      console.log("watch HostType");
      var HostType = this.HostType;
      console.log({ HostType });
      if (!HostType) {
        var rows = this.RecordData;
        var n1 =
          typeof rows === "object" && rows !== null
            ? Object.keys(rows).length
            : 0;
        console.log({ n1 });
        var id1 = n1 > 0 ? rows.HostZoneId : "";
        console.log({ id1 });
        this.HostZoneCode = id1;
      }
    },
    HostLomCodeOptions: function () {
      console.log("watch HostLomCodeOptions");
      this.LoadingFlag = false;
      this.HostLomCodeOptionsLoading = false;
      var rows = this.RecordData;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log({ n1 });
      var id1 = n1 > 0 ? rows.HostLomId : "";
      console.log({ id1 });
      var idx = this.HostLomCodeOptions.map((e) => e.value).indexOf(id1);
      console.log({ idx });
      this.HostLomCode = idx >= 0 ? id1 : "";
    },
    ParticipantZoneType: function () {
      console.log("watch ParticipantZoneType");
      var ParticipantZoneType = this.ParticipantZoneType;
      console.log({ ParticipantZoneType });
      var defaultValue = [];
      if (!ParticipantZoneType) {
        var rows = this.RecordData;
        var n1 =
          typeof rows === "object" && rows !== null
            ? Object.keys(rows).length
            : 0;
        console.log({ n1 });
        defaultValue = n1 > 0 ? rows.ParticipantZoneId : [];
      }
      console.log({ defaultValue });
      this.ParticipantZoneCode = defaultValue;
    },
    ParticipantZoneCode: function () {
      console.log("watch ParticipantZoneCode");
      this.getParticipantLomCodeOptions();
    },
    ParticipantZoneCodeOptions: function () {
      console.log("watch ParticipantZoneCodeOptions");
      this.ParticipantZoneCodeOptionsLoading = false;
    },
    ParticipantLomCodeOptions: function () {
      console.log("watch ParticipantLomCodeOptions");
      this.ParticipantLomCodeOptionsLoading = false;
    },
    ParticipantLomType: function () {
      console.log("watch ParticipantLomType");
      var ParticipantLomType = this.ParticipantLomType;
      console.log({ ParticipantLomType });
      var defaultValue = [];
      if (!ParticipantLomType) {
        var rows = this.RecordData;
        var n1 =
          typeof rows === "object" && rows !== null
            ? Object.keys(rows).length
            : 0;
        console.log({ n1 });
        defaultValue = n1 > 0 ? rows.ParticipantLomId : [];
      }
      console.log({ defaultValue });
      this.ParticipantLomCode = defaultValue;
    },
    ParticipantAllDesignation: function () {
      console.log("watch ParticipantAllDesignation");
      var ParticipantAllDesignation = this.ParticipantAllDesignation;
      console.log({ ParticipantAllDesignation });
      var ParticipantAllDesignationFlag =
        ParticipantAllDesignation == 1 ? false : true;
      console.log({ ParticipantAllDesignationFlag });
      this.ParticipantAllDesignationFlag = ParticipantAllDesignationFlag;
      if (!ParticipantAllDesignationFlag) {
        this.NationalDesigntionType = false;
        this.NationalDesigntion = [];
        this.ZoneDesigntionType = false;
        this.ZoneDesigntion = [];
        this.LomDesigntionType = false;
        this.LomDesigntion = [];
        this.CommitteeDesigntionType = false;
        this.CommitteeDesigntion = [];
      }
    },
    NationalDesigntionType: function () {
      console.log("watch NationalDesigntionType");
      var NationalDesigntionType = this.NationalDesigntionType;
      console.log({ NationalDesigntionType });
      var defaultValue = [];
      if (!NationalDesigntionType) {
        var rows = this.RecordData;
        var n1 =
          typeof rows === "object" && rows !== null
            ? Object.keys(rows).length
            : 0;
        console.log({ n1 });
        defaultValue = n1 > 0 ? rows.NationalDesigntion : [];
      }
      console.log({ defaultValue });
      this.NationalDesigntion = defaultValue;
    },
    NationalDesigntionOptions: function () {
      console.log("watch NationalDesigntionOptions");
      this.NationalDesigntionOptionsLoading = false;
    },
    ZoneDesigntionType: function () {
      console.log("watch ZoneDesigntionType");
      var ZoneDesigntionType = this.ZoneDesigntionType;
      console.log({ ZoneDesigntionType });
      var defaultValue = [];
      if (!ZoneDesigntionType) {
        var rows = this.RecordData;
        var n1 =
          typeof rows === "object" && rows !== null
            ? Object.keys(rows).length
            : 0;
        console.log({ n1 });
        defaultValue = n1 > 0 ? rows.ZoneDesigntion : [];
      }
      console.log({ defaultValue });
      this.ZoneDesigntion = defaultValue;
    },
    ZoneDesigntionOptions: function () {
      console.log("watch ZoneDesigntionOptions");
      this.ZoneDesigntionOptionsLoading = false;
    },
    LomDesigntionType: function () {
      console.log("watch LomDesigntionType");
      var LomDesigntionType = this.LomDesigntionType;
      console.log({ LomDesigntionType });
      var defaultValue = [];
      if (!LomDesigntionType) {
        var rows = this.RecordData;
        var n1 =
          typeof rows === "object" && rows !== null
            ? Object.keys(rows).length
            : 0;
        console.log({ n1 });
        defaultValue = n1 > 0 ? rows.LomDesigntion : [];
      }
      console.log({ defaultValue });
      this.LomDesigntion = defaultValue;
    },
    LomDesigntionOptions: function () {
      console.log("watch LomDesigntionOptions");
      this.LomDesigntionOptionsLoading = false;
    },
    CommitteeDesigntionType: function () {
      console.log("watch CommitteeDesigntionType");
      var CommitteeDesigntionType = this.CommitteeDesigntionType;
      console.log({ CommitteeDesigntionType });
      var defaultValue = [];
      if (!CommitteeDesigntionType) {
        var rows = this.RecordData;
        var n1 =
          typeof rows === "object" && rows !== null
            ? Object.keys(rows).length
            : 0;
        console.log({ n1 });
        defaultValue = n1 > 0 ? rows.CommitteeDesigntion : [];
      }
      console.log({ defaultValue });
      this.CommitteeDesigntion = defaultValue;
    },
    CommitteeDesigntionOptions: function () {
      console.log("watch CommitteeDesigntionOptions");
      this.CommitteeDesigntionOptionsLoading = false;
    },
  },
  methods: {
    remove(item, id = 1) {
      console.log("remove called");
      console.log({ item });
      console.log({ id });
      var index = "";
      switch (id) {
        case 1:
          index = this.ParticipantZoneCode.indexOf(item.value);
          console.log({ index });
          if (index >= 0) this.ParticipantZoneCode.splice(index, 1);
          break;

        case 2:
          index = this.NationalDesigntion.indexOf(item.value);
          console.log({ index });
          if (index >= 0) this.NationalDesigntion.splice(index, 1);
          break;

        case 3:
          index = this.ZoneDesigntion.indexOf(item.value);
          console.log({ index });
          if (index >= 0) this.ZoneDesigntion.splice(index, 1);
          break;

        case 4:
          index = this.LomDesigntion.indexOf(item.value);
          console.log({ index });
          if (index >= 0) this.LomDesigntion.splice(index, 1);
          break;

        case 5:
          index = this.CommitteeDesigntion.indexOf(item.value);
          console.log({ index });
          if (index >= 0) this.CommitteeDesigntion.splice(index, 1);
          break;

        case 6:
          index = this.ParticipantLomCode.indexOf(item.value);
          console.log({ index });
          if (index >= 0) this.ParticipantLomCode.splice(index, 1);
          break;

        default:
          break;
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      //   this.resetForm();
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jci_events",
        Action: "edit",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getPageData() {
      console.log("getPageData called");
      var yearCode = this.JciYearCode;
      console.log({ yearCode });
      this.getEventTypeOptions();
      this.getStateIdOptions();

      // get participant zone options
      this.ParticipantZoneCode = "";
      this.ParticipantZoneCodeOptionsLoading = true;
      this.getZoneCodeOptions(
        "ParticipantZoneCode",
        "ParticipantZoneCodeOptions",
        {
          UserInterface: 2,
          YearCode: yearCode,
        }
      );

      // get host zone options

      this.HostZoneCode = "";
      this.HostZoneCodeOptionsLoading = true;
      this.getZoneCodeOptions("HostZoneCode", "HostZoneCodeOptions", {
        UserInterface: 2,
        YearCode: yearCode,
      });

      // get NGB designation options

      this.NationalDesigntion = "";
      this.NationalDesigntionOptionsLoading = true;
      this.getDesignationOptions(
        "NationalDesigntion",
        "NationalDesigntionOptions",
        {
          UserInterface: 4,
          OrganizationTypeId: 1,
          CategorySubName: "designation_national",
        }
      );

      // get ZGB designation options

      this.ZoneDesigntion = "";
      this.ZoneDesigntionOptionsLoading = true;
      this.getDesignationOptions("ZoneDesigntion", "ZoneDesigntionOptions", {
        UserInterface: 4,
        OrganizationTypeId: 2,
        CategorySubName: "designation_zone",
      });

      // get LGB designation options

      this.LomDesigntion = "";
      this.LomDesigntionOptionsLoading = true;
      this.getDesignationOptions("LomDesigntion", "LomDesigntionOptions", {
        UserInterface: 4,
        OrganizationTypeId: 3,
        CategorySubName: "designation_lo",
      });

      // get Committee designation options

      this.CommitteeDesigntion = "";
      this.CommitteeDesigntionOptionsLoading = true;
      this.getDesignationOptions(
        "CommitteeDesigntion",
        "CommitteeDesigntionOptions",
        {
          UserInterface: 4,
          OrganizationTypeId: 1,
          CategorySubName: "designation_national_committee",
        }
      );
    },
    resetForm() {
      //   this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.$refs.form3.reset();
      this.$refs.form4.reset();
    },
    getEventTypeDetails() {
      console.log("getEventTypeDetails called");
      this.AllMembers = [];
      var temp_code = this.EventType;
      console.log("temp_code=" + temp_code);
      var idx = this.EventTypeOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var item = this.EventTypeOptions[idx];
        console.log({ item });
        this.SelectedEventType = item;
      }
    },
    getZoneCodeOptions(source, destination, request) {
      console.log("getZoneCodeOptions called");
      if (source != "" && destination != "") {
        var selectbox1_url = "api/year-wise-lom/zone-options";
        this.getSelectBoxOptions(source, destination, request, selectbox1_url);
      }
    },
    getDesignationOptions(source, destination, request) {
      console.log("getDesignationOptions called");
      if (source != "" && destination != "") {
        var selectbox1_url = "api/designation/options";
        this.getSelectBoxOptions(source, destination, request, selectbox1_url);
      }
    },
    getHostLomCodeOptions() {
      console.log("getHostLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var HostZoneCode = this.HostZoneCode;
      this.HostLomCode = "";
      console.log("YearCode=" + YearCode + ", HostZoneCode=" + HostZoneCode);
      if (YearCode != "" && HostZoneCode != "") {
        this.LoadingFlag = true;
        this.HostLomCodeOptionsLoading = true;
        var selectbox1_source = "HostLomCode";
        var selectbox1_destination = "HostLomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          ZoneCode: HostZoneCode,
          AllOption: 0,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (HostZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getParticipantLomCodeOptions() {
      console.log("getParticipantLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ParticipantZoneCode = this.ParticipantZoneCode;
      this.ParticipantLomCode = [];
      console.log("YearCode=" + YearCode);
      console.log({ ParticipantZoneCode });
      var t1 = ParticipantZoneCode.length;
      console.log({ t1 });
      if (YearCode != "" && t1 > 0) {
        this.ParticipantLomCodeOptionsLoading = true;
        var selectbox1_source = "ParticipantLomCode";
        var selectbox1_destination = "ParticipantLomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          ZoneCode: this.HostZoneCode,
          AllOption: 0,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (t1 == 0) {
          message += "Atleast one zone required. ";
        }
        this.toast("error", message);
      }
    },
    getEventTypeOptions() {
      console.log("getEventTypeOptions called");
      this.EventType = "";
      this.EventTypeOptionsLoading = true;
      var selectbox1_source = "EventType";
      var selectbox1_destination = "EventTypeOptions";
      var selectbox1_url = "api/jci-program-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getStateIdOptions() {
      console.log("getStateIdOptions called");
      this.LoadingFlag = true;
      this.StateIdOptionsLoading = true;
      var selectbox1_source = "StateId";
      var selectbox1_destination = "StateIdOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictIdOptions() {
      console.log("getDistrictIdOptions called");
      var StateId = this.StateId;
      console.log({ StateId });
      if (StateId != "") {
        this.LoadingFlag = true;
        this.DistrictIdOptionsLoading = true;
        var selectbox1_source = "DistrictId";
        var selectbox1_destination = "DistrictIdOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityIdOptions() {
      console.log("getCityIdOptions called");
      var DistrictId = this.DistrictId;
      if (DistrictId != "") {
        this.LoadingFlag = true;
        this.CityIdOptionsLoading = true;
        var selectbox1_source = "CityId";
        var selectbox1_destination = "CityIdOptions";
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    validateStep(step) {
      console.log("validateStep step=" + step);
      var flag = false;
      var submitFlag = false;
      var validate = false;
      var nextStep = 0;
      var message = "Kindly fill the required fields. ";
      switch (step) {
        case 1:
          validate = this.$refs.form3.validate();
          message = validate ? "" : message;
          console.log({ validate });

          var FromDate = this.FromDate;
          var ToDate = this.ToDate;
          flag = validate && FromDate != "" && ToDate != "" ? true : false;
          console.log({ flag });

          message += FromDate == "" ? "From Date should not be empty. " : "";
          message += ToDate == "" ? "To Date should not be empty. " : "";

          nextStep = 2;
          // flag = true;

          break;

        case 2:
          validate = this.$refs.form2.validate();
          message = validate ? "" : message;
          console.log({ validate });

          flag = validate ? true : false;
          console.log({ flag });

          nextStep = 3;
          // flag = true;
          break;

        case 3:
          validate = this.$refs.form1.validate();
          message = validate ? "" : message;
          console.log({ validate });

          var ParticipantZoneType = this.ParticipantZoneType;
          console.log({ ParticipantZoneType });

          var ParticipantZoneCodeCount = this.ParticipantZoneCode.length;
          console.log({ ParticipantZoneCodeCount });

          var ParticipantZoneCodeFlag =
            !ParticipantZoneType && ParticipantZoneCodeCount == 0
              ? false
              : true;
          console.log({ ParticipantZoneCodeFlag });

          var ParticipantLomType = this.ParticipantLomType;
          console.log({ ParticipantLomType });

          var ParticipantLomCodeCount = this.ParticipantLomCode.length;
          console.log({ ParticipantLomCodeCount });

          var ParticipantLomCodeFlag =
            !ParticipantZoneType &&
            !ParticipantLomType &&
            ParticipantLomCodeCount == 0
              ? false
              : true;
          console.log({ ParticipantLomCodeFlag });

          var ParticipantNonMember = this.ParticipantNonMember;
          console.log({ ParticipantNonMember });

          var ParticipantAllDesignation = this.ParticipantAllDesignation;
          console.log({ ParticipantAllDesignation });

          var flag1 =
            !ParticipantZoneCodeFlag || !ParticipantLomCodeFlag ? false : true;
          console.log({ flag1 });

          message += !ParticipantZoneCodeFlag
            ? "Select atleast one zone. "
            : "";

          message += !ParticipantLomCodeFlag ? "Select atleast one LO. " : "";

          var flag2 = true;

          if (!ParticipantAllDesignation) {
            var NationalDesigntionType = this.NationalDesigntionType;
            console.log({ NationalDesigntionType });

            var NationalDesigntionCount = this.NationalDesigntion.length;
            console.log({ NationalDesigntionCount });

            var ZoneDesigntionType = this.ZoneDesigntionType;
            console.log({ ZoneDesigntionType });

            var ZoneDesigntionCount = this.ZoneDesigntion.length;
            console.log({ ZoneDesigntionCount });

            var LomDesigntionType = this.LomDesigntionType;
            console.log({ LomDesigntionType });

            var LomDesigntionCount = this.LomDesigntion.length;
            console.log({ LomDesigntionCount });

            var CommitteeDesigntionType = this.CommitteeDesigntionType;
            console.log({ CommitteeDesigntionType });

            var CommitteeDesigntionCount = this.CommitteeDesigntion.length;
            console.log({ CommitteeDesigntionCount });

            var TotalDesignationCount =
              parseInt(NationalDesigntionCount) +
              parseInt(ZoneDesigntionCount) +
              parseInt(LomDesigntionCount) +
              parseInt(CommitteeDesigntionCount);
            console.log({ TotalDesignationCount });

            var flag2 =
              !ParticipantAllDesignation && TotalDesignationCount == 0
                ? false
                : true;
            console.log({ flag2 });

            message += !flag2
              ? "Atleast one designation should be selected in NGB or ZGB or LGB or Committee. "
              : "";
          }
          console.log({ flag1 });
          console.log({ flag2 });

          flag = flag1 && flag2 ? true : false;

          nextStep = 4;
          submitFlag = true;
          break;

        case 4:
          validate = this.$refs.form4.validate();
          flag = validate ? true : false;
          nextStep = 5;
          break;

        default:
          break;
      }
      console.log(
        "flag=" + flag + ", nextStep=" + nextStep + ", submitFlag=" + submitFlag
      );
      if (flag) {
        if (submitFlag) {
          this.confirmAlert();
        } else {
          this.step = nextStep;
        }
      } else {
        // this.sweetAlert("error", "Kindly fill the required * fields", false);
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this! Kindly check the information are correct",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Continue`,
        confirmButtonColor: "#3085d6",
        cancelButtonText: `Cancel`,
        cancelButtonColor: "#d33",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.submitForm();
        } else {
          this.sweetAlert("error", "You cancelled the process", true);
        }
      });
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/jci-program/show";
      var upload = {
        UserInterface: 1,
        Program: this.ProgramId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      this.LoadingFlag = true;
      this.RecordData = {};

      const thisIns = this;
      var output = "";
      var records = {};
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.LoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);

          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.LoadingFlag = false;
            thisIns.RecordData = records;
            thisIns.toast("success", output);
          } else {
            thisIns.LoadingFlag = false;
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
        });
    },
    submitForm() {
      console.log("submitForm is called");

      var ParticipantZoneCode = this.ParticipantZoneCode;
      var ParticipantLomCode = this.ParticipantLomCode;
      var NationalDesigntion = this.NationalDesigntion;
      var ZoneDesigntion = this.ZoneDesigntion;
      var LomDesigntion = this.LomDesigntion;
      var CommitteeDesigntion = this.CommitteeDesigntion;

      var RecordData = this.RecordData;
      console.log({ RecordData });

      var ProgramId = RecordData.ProgramId;
      console.log({ ProgramId });

      var upload = {
        UserInterface: 1,
        Event: ProgramId,
        Year: RecordData.YearId,
        EventType: this.EventType,
        FromDate: this.FromDate,
        ToDate: this.ToDate,
        CustomName: this.CustomName,
        Venue: this.Venue,
        City: this.CityId,
        RegistrationOverallCount: this.RegistrationOverallCount,
        RegistrationUserCount: this.RegistrationUserCount,
        HostType: this.HostType,
        HostZone: this.HostZoneCode,
        HostLom: this.HostLomCode,
        ParticipantZoneType: this.ParticipantZoneType,
        ParticipantZone:
          ParticipantZoneCode.length > 0 ? ParticipantZoneCode : null,
        ParticipantLomType: this.ParticipantLomType,
        ParticipantLom:
          ParticipantLomCode.length > 0 ? ParticipantLomCode : null,
        ParticipantNonMember: this.ParticipantNonMember,
        ParticipantAllDesignation: this.ParticipantAllDesignation,
        NationalDesigntionType: this.NationalDesigntionType,
        NationalDesigntion:
          NationalDesigntion.length > 0 ? NationalDesigntion : null,
        ZoneDesigntionType: this.ZoneDesigntionType,
        ZoneDesigntion: ZoneDesigntion.length > 0 ? ZoneDesigntion : null,
        LomDesigntionType: this.LomDesigntionType,
        LomDesigntion: LomDesigntion.length > 0 ? LomDesigntion : null,
        CommitteeDesigntionType: this.CommitteeDesigntionType,
        CommitteeDesigntion:
          CommitteeDesigntion.length > 0 ? CommitteeDesigntion : null,
      };
      console.log("upload=" + JSON.stringify(upload));

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var add_url = server_url + "api/jci-program/update";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      this.SubmitFlag = true;

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SubmitFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);
          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.$router.push("/jci-event/report");
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SubmitFlag = false;
        });
    },
  },
  beforeMount() {
    console.log("jci-program-create beforeMount");

    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#jci-program-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-card__subtitle,
  .v-card__text {
    font-size: 16px;
  }

  .v-list-item--dense .v-list-item__title,
  .v-list-item--dense .v-list-item__subtitle,
  .v-list--dense .v-list-item .v-list-item__title,
  .v-list--dense .v-list-item .v-list-item__subtitle {
    font-size: 18px !important;
  }
  .mycard-text1 {
    font-family: sans-serif;
    // text-transform: capitalize;
  }
  .fsize-3 {
    font-size: 16px !important;
  }
}
</style>